

import { Box } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';

const D3DImageView = (props)=>{
    const {images} = props;
    // let images = ['https://pim.ivymobi.net:44391/cdn/f19fb95e-eda6-49ba-af93-70ddce588269/ecc0e69a-c40b-44e5-97c1-8620ec5c73b7.png']
    const [sources, setSources] = useState<HTMLImageElement[]>([]);
    const [loadCount, setLoadCount] = useState(0);
    let preIndex = 0;
    let curIndex = 0;
    const onDragMove = (index)=>{
        for(let i=0;i<images.length;i++){
            let pre = document.getElementById(i+'');
            pre.style.visibility='hidden'
        }
        let show = document.getElementById(index%images.length+'');
        show.style.visibility='visible'
        // preIndex = i;
    }
    if(images.length==0)
        return null;
    return <Box onMouseDown={(ev)=>{
        //event的兼容性
 
        //获取鼠标按下的坐标
        var x1 = ev.clientX;
        var y1 = ev.clientY;
        var dis = 0;
        var preX =0;
        document.onmousemove=(ev)=>{
            //event的兼容性
            //获取鼠标移动时的坐标
            var x2 = ev.clientX;
            var y2 = ev.clientY;
 
            //计算出鼠标的移动距离
            var x = x2-x1;
            var y = y2-y1;
            if(ev.clientX-preX>0){
                let inde = ++preIndex;
                let real = 0;
                if(inde<0){
                    real  = images.length+preIndex%images.length
                }else{
                    real = inde
                }
                onDragMove(real)
            }
            else{
                let inde = --preIndex;
                let real = 0;
                if(inde<0){
                    real  = images.length+preIndex%images.length
                }else{
                    real = inde
                }
                onDragMove(real) 
            }
            preX = ev.clientX;
        }
        document.onmouseup = function(ev){
 
            document.onmousemove = null;
 
        }
    }} style={{position:'relative',display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px',width:'100%', height:'20vw'}}>
        {images.map((item,i)=>{
            return <img id={i}   src={item} draggable={false} style={{width:'100%',height:'100%',visibility:preIndex==i?'visible':'hidden',WebkitUserSelect:'none',position:'absolute',left:'0px',top:'0',right:"0px",bottom:'0px',objectFit:"contain"}} onLoad={()=>{
                setLoadCount(loadCount+1)
            }}/> 
        })}
        {loadCount!=images.length&&<Box style={{background:'white',position:'absolute',left:'0px',top:'0',right:"0px",bottom:'0px',display:'flex',justifyContent:"center",alignItems:'center  '}}>
            <p>{Math.round(loadCount*100/images.length) +'%'}</p>
        </Box>}
    </Box>
}

export default D3DImageView;