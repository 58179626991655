import * as React from 'react';
import { Toolbar, ToolbarProps } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => createStyles({
  toolbar: {
    backgroundColor: theme.palette.grey[100],
    justifyContent: 'flex-end'
  }
}));

export default function FToolbar(props: ToolbarProps) {
  const { children, ...rest } = props;
  const classes = useStyles();

  return (
    <Toolbar style={{display:'flex',alignItems:'center',justifyContent:'center'}} className={classes.toolbar} {...rest}>
      {children}
    </Toolbar> 
  );
}
