import { get } from 'lodash';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Radio,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Validate } from './validate';

function safeParseInt (str: string) {
  const res = parseInt(str);
  return isNaN(res) ? str : res; 
}

const useStyles = makeStyles(theme => createStyles({
  root: {
    margin: theme.spacing(2, 0, 1, 0)
  }
}));

interface RadioGroupsInputProps<T> {
  name: string;
  label?: string;
  validate?: Validate[];
  options: Array<T>;
  getOptionKey?: (option: T) => React.Key;
  getOptionLabel?: (option: T) => string | number;
  getOptionValue?: (option: T) => unknown;
  row?: boolean;
  disabled?: boolean;
}

export default function RadioGroupsInput<T>(props: RadioGroupsInputProps<T>) {
  const defaultGetOptionKey = (option) => get(option, 'id');
  const defaultGetOptionLabel = (option) => get(option, 'label');
  const defaultgetOptionValue = (option) => get(option, 'value');

  const {
    name, label = name,
    validate = [], row,
    options,
    disabled,
    getOptionKey = defaultGetOptionKey,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultgetOptionValue
  } = props;

  const classes = useStyles();
  const methods = useFormContext();
  const { errors, control } = methods;
  const defaultValue = get(control.defaultValuesRef.current, name, []);

  const error = get(errors, name);
  const errorMessage = get(errors, [name, 'message']);

  const validations = validate.reduce((acc, v) => Object.assign(acc, v), {});
  const required = Boolean(get(validations, 'required', false));

  const getOptionSelected = (values = '', option) => {
    return values === getOptionValue(option);
  };

  const handleChange = (e, onChange) => {
    const value = safeParseInt(e.target.value);
    onChange(value);
  };

  return (
    <FormControl component="fieldset" className={classes.root}
      required={required} error={Boolean(error)}
    >
      <FormLabel >{label}</FormLabel>
      <FormGroup row={row} title={label} >
        <Controller 
          control={control} name={name} rules={validations}
          defaultValue={defaultValue}
          render={({ onChange, value, name }) => (
            <RadioGroup 
              name={name} 
              value={value} 
              onChange={(e) => {
                handleChange(e, onChange);
              }}
              row={row}
            >
              {
                options.map(option => (
                  <FormControlLabel
                    key={getOptionKey(option)}
                    value={getOptionValue(option)} 
                    control={<Radio />}
                    checked={getOptionSelected(value, option)}
                    label={getOptionLabel(option)}
                    disabled={disabled}
                  />
                ))
              }
            </RadioGroup>
          )}>
        </Controller>
      </FormGroup>
      <Typography variant="caption" color="error">
        {errorMessage}
      </Typography>
    </FormControl>
  );
}