import * as React from 'react';
import {
  Box,
  FormControl,
  InputLabel, Typography
} from '@mui/material';

interface LabelProps {
  label: string;
  id?: string;
  children: React.ReactNode;
}
export default function Label(props: LabelProps) {
  const { id, label, children } = props;

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <Box>
        <Typography variant="subtitle1" style={{padding:'4px', fontSize:'15px', color:'gray'}}>{label}</Typography>
      </Box>
      {children}
    </Box>
  );
}