// const HEADING_FONT = 'Europa, sans-serif';
const BODY_FONT =
  '"Microsoft YaHei", "PingFangSC-Regular", "sans-serif", SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace';
//'"Open Sans", sans-serif';

// const MONO_FONT =
// 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace';

export default {
  sidebar: {
    width: 240,
    closedWidth: 55,
    fontFamily: BODY_FONT,
  },
  palette: {
    primary: {
      main: '#005ae7',
    },
    background: {
      default: '#e5e5e5', 
    },
  },
  typography: {
    fontFamily: BODY_FONT,
  },
  overrides: {
    OrbitMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #4f3cc9',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: '#6586ff20',
          '&:hover': {
            backgroundColor: '#6586ff20',
          }
        }
      }
    }
  },
};
