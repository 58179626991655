import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { get } from 'lodash';

import { Validate } from './validate';

type TextInputProps = TextFieldProps & {
  name: string;
  validate?: Validate[]
}

export default function TextInput(props: TextInputProps) {
  const { validate = [], required, ...rest } = props;
  const { name, label = name } = rest;
  const { register, errors } = useFormContext();
  
  const error = get(errors, name);
  // const errorMessage = get(errors, [name, 'message']);
  const errorMessage = error?.message;

  const validations = validate.reduce((acc, v) => Object.assign(acc, v), {});
  const isRequired = required || Boolean(get(validations, 'required', false));
  return (
    <TextField label={label} margin="normal" variant="outlined"
      {...rest}
      required={isRequired}
      inputRef={register(validations)}
      error={Boolean(error)}
      helperText={errorMessage}
    />
  );
}