import * as React from 'react';
import {
  useForm, FormProvider,
} from 'react-hook-form';
import { Box, BoxProps } from '@mui/material';

import FToolbar from './toolbar';
import SaveButton from './SaveButton';
import SaveButtonValid from './SaveButtonValid';

function Toolbar() {
  return (
    <FToolbar>
      <SaveButton />
    </FToolbar>
  );
}
export function ToolbarValid() {
  return (
    <FToolbar>
      <SaveButtonValid />
    </FToolbar>
  );
}
type IFormProps = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement
> & {
  defaultValues?: {[k: string]: any };
  toolbar?: React.ReactNode;
} & BoxProps;

export default function Form(props: IFormProps) {
  const {
    defaultValues={},
    toolbar = <Toolbar />,
    children, onSubmit,
    ...rest
  } = props;
  const methods = useForm({
    defaultValues,
    mode: 'onChange'
  });

  const handleSubmit = async (e) => {
    await onSubmit(e);
    methods.reset(e);
  };

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(handleSubmit)}
        {...rest}
      >
        <Box pb={2} px={4} {...rest} >
          {children}
        </Box>
        {toolbar}
      </form>
    </FormProvider>
  );
}