import React, {
  useState
} from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import {
  InputLabel, MenuItem, FormControl, Select, Box, Button, Typography, TextField, useMediaQuery, useTheme, InputAdornment,
} from '@mui/material';
import 'react-phone-number-input/style.css';

import PropTypes from 'prop-types';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { sendLoginMobileCodeAction } from '@ivymobi/orbit-core/business/user';
import { SendSmsCodeButton } from './smsCode';
import { useTranslation } from 'react-i18next';
import { getCurLanuagePhone } from '@ivymobi/orbit-core/business/phoneNumber';

function SigninForm({login}) {
  const dispath = useDispatch();
  const [t,i18n] = useTranslation();
  const { register, handleSubmit, errors, getValues, watch, formState, setValue } = useForm<{
    mobile: string;
    code: string;
    countryCode: string;
  }>({ mode: 'onChange', defaultValues: { mobile: '', code: '', countryCode:'+86' } });
  const mobile = watch('mobile');
  const [flush, setFlush]=useState(true);
  register({name:'countryCode'});
  const doSendCode = () => dispath(
    sendLoginMobileCodeAction({ mobile:getValues('mobile'), countryCode:getValues('countryCode')})
  );
  const handleChange = (event) => {
    setValue('countryCode', '+'+event.target.value);
    setFlush(!flush);
    console.log('======', getValues('countryCode').replace('+', ''));
  };

  const CountrySelect = ({ value, onChange, labels, ...rest }) => {
    return (
      <FormControl fullWidth required >
        <InputLabel id="demo-simple-select-label">{t('login.country')}</InputLabel>
        <Select
          name='countryCode'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={getValues('countryCode').replace('+', '')}
          label="countryCode"
          onChange={handleChange}
        >
          {getCountries().map((country) => (
            <MenuItem key={country} value={getCountryCallingCode(country)}>{labels[country]}</MenuItem>

          ))}
        </Select>
      </FormControl>
    );};

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired
  };
  return (
    <form onSubmit={handleSubmit(login, console.error)}
          style={{ maxWidth: '264px' }}
    >
      <CountrySelect
        labels={getCurLanuagePhone(i18n)}
        value={getValues('countryCode')}
        onChange={() => {}}/>
      <TextField 
        label={t('YBT-ADMIN.YBT-ADMIN-030101.YBT-ADMIN-03010107')} 
        placeholder={t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010320')} 
        fullWidth 
        margin="normal"
        name="mobile" required
        inputRef={register({ required: true, minLength: 5 })}
        error={Boolean(errors.mobile)}
        helperText={errors.mobile ? t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010321') : ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">{getValues('countryCode')}</InputAdornment>,
        }}
      />
      <TextField 
        label={t('share.form.code')} 
        placeholder={t('login.verificationCodePlaceholder')} 
        fullWidth 
        margin="normal"
        name="code" required
        autoComplete="off"
        inputRef={register({ required: true, minLength: 3 })}
        error={Boolean(errors.code)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ marginBottom: '24px' }}>
              <SendSmsCodeButton
                disabled={Boolean((mobile || '').length === 0 || errors.mobile)}
                doSendCode={doSendCode}
              />
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        type="submit"
        disabled={!(formState.isValid && formState.isDirty)}
      >
        {t('auth.signin')}
      </Button>
    </form>
  );
}

const SignIn = ({login}) => {
  const [t] = useTranslation();
  return (
    <Box>
      <Logo />
      <SigninForm login={login} />
      <Box p={2} />
      <Box display="flex">
        <Typography variant="subtitle2">
          <Link to="/user/wechat">{t('login.wechat')}</Link>
        </Typography>
      </Box>
    </Box>
  );
};

function SignupForm({signup}) {
  const dispath = useDispatch();
  const { register, handleSubmit, errors, getValues, watch, formState, setValue} = useForm<{
    mobile: string;
    code: string;
    countryCode: string;
  }>({ mode: 'onChange', defaultValues: { mobile: '', code: '', countryCode:'+86' }});
  const [flush, setFlush]=useState(true);
  register({name:'countryCode'});
  const handleChange = (event) => {
    setValue('countryCode', '+'+event.target.value);
    setFlush(!flush);
  };

  const CountrySelect = ({ value, onChange, labels, ...rest }) => {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t('login.country')}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name='countryCode'
          value={getValues('countryCode').replace('+', '')}
          label="countryCode"
          onChange={handleChange}
        >
          {getCountries().map((country) => (
            <MenuItem key={country} value={getCountryCallingCode(country)}>{labels[country]}</MenuItem>

          ))}

        </Select>
      </FormControl>
    );
  };

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired
  };
  const mobile = watch('mobile');
  const [t,i18n] = useTranslation();
  const doSendCode = () => dispath(
    sendLoginMobileCodeAction({ mobile:getValues('mobile'), countryCode:getValues('countryCode') })
  );
  return (
    <form 
      onSubmit={handleSubmit(signup)}
      style={{ maxWidth: '264px' }}
    >
      <CountrySelect
        labels={getCurLanuagePhone(i18n)}
        value={getValues('countryCode')}
        onChange={() => {}}/>
      <TextField 
        label={t('YBT-ADMIN.YBT-ADMIN-030101.YBT-ADMIN-03010107')} placeholder={t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010320')} fullWidth margin="normal"
        name="mobile" required
        inputRef={register({ required: true, minLength: 5 })}
        error={Boolean(errors.mobile)}
        helperText={errors.mobile?.message || errors.mobile?.type}
        InputProps={{
          startAdornment: <InputAdornment position="start">{getValues('countryCode')}</InputAdornment>,
        }}
      />
      <TextField label={t('share.form.code')} placeholder={t('login.verificationCodePlaceholder')} fullWidth margin="normal"
                 autoComplete="off"
                 name="code" required
                 inputRef={register({ required: true })}
                 error={Boolean(errors.mobile)}
                 helperText={errors.code?.message || errors.code?.type}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end" style={{ marginBottom: '24px' }}>
                       <SendSmsCodeButton
                         disabled={Boolean((mobile || '').length === 0 || errors.mobile)}
                         doSendCode={doSendCode}
                       />
                     </InputAdornment>
                   )
                 }}
      />
      <Button variant="contained" color="primary" fullWidth type="submit"
              disabled={!formState.isValid}
      >
        {t('login.freeRegister')}
      </Button>
    </form>
  );
}

function Signup({signup}) {
  const [t] = useTranslation();
  return (
    <Box>
      <Logo />
      <SignupForm signup={signup} />
      <Box p={2} />
      <Box display="flex">
        <Typography variant="subtitle2">
          <Link to="/user/wechat">{t('login.wechat')}</Link> {t('login.or')} <Link to="/user/signin">{t('login.phoneToLogin')}</Link>
        </Typography>
      </Box>
    </Box>

  );
}

const Wechat = () => {
  const [t] = useTranslation();
  return (
    <div>
      <Logo />
      <Box p={1} />
      <Typography gutterBottom>{t('login.wechat')}</Typography>
      <img src="/images/wechat.jpg" height={200} width={200} />
      <Box display="flex" pt={1}>
        <Typography variant="subtitle2">
          {t('login.usePhone')} <Link to="/user/signup">{t('auth.signup')}</Link>
        </Typography>
      </Box>
    </div>
  );
};

const Logo = () => {
  const [t] = useTranslation();
  return (
    <div>
      <img
        src="/images/logo.png"
        width={124}
        height="auto"
      />
      <Box p={1} />
      <Typography variant="h6" noWrap>
        {t('login.slogan')}
      </Typography>
    </div>
  )};

const User = ({login, signup}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const bgImageStyle = isSmall
    ? {
      justifyContent: 'center'
    }
    : {
      paddingLeft: '18%',
      backgroundImage: 'url(/images/signup.png)',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
    };

  return (
    <Box
      display="flex" width="100%" height="100vh"
      paddingTop="30vh"
      style={bgImageStyle}
    >
      <Switch>
        <Route exact path={['/user/signin', '/user']}>
          <SignIn login={login} />
        </Route>
        <Route exact path="/user/signup">
          <Signup signup={signup} />
        </Route>
        <Route exact path="/user/wechat">
          <Wechat />
        </Route>
      </Switch>
    </Box>
  );
};

export default User;
