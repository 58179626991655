import { Typography } from '@mui/material';
import * as React from 'react';

const ShowContext = React.createContext(null);

export function useShowContext() {
  return React.useContext(ShowContext);
}

interface ShowProps {
  value: unknown;
  title?: string;
  children: React.ReactNode;
}
export default function Show(props: ShowProps) {
  const { value, title, children } = props;

  return (
    <ShowContext.Provider value={value}>
      {title && <Typography variant="h5" paragraph>{title}</Typography>}
      {children}
    </ShowContext.Provider>
  );
}