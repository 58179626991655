import en from 'react-phone-number-input/locale/en.json';
import zh from 'react-phone-number-input/locale/zh.json';
import { useTranslation } from 'react-i18next';

zh.HK = '中国香港'
zh.TW = '中国台湾'
zh.MO = '中国澳门'
en.HK = 'Chinese Hong kong'
en.TW = 'Chinese Taiwan'
en.MO = 'Chinese Macao'
let locals = { zh, en };
const getCurLanuagePhone =(i18n) => {
  const lan = i18n.language === 'zh-CN' ? 'zh' : i18n.language;
  return locals[lan]??locals['en'];
};

export  {
  en,
  zh,
  getCurLanuagePhone
};
