import { isEmpty, isNull } from 'lodash';
import React, {
  lazy, FunctionComponent, Suspense, useCallback, useEffect, useState,
} from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter, Route, Switch, Redirect, useHistory, useParams,
} from 'react-router-dom';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  MenuItem,
  ListItemIcon,
  adaptV4Theme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  loginAction, logoutAction, signupAction,
  resumeLastSessionAction, acceptInvitationWithRegisterAction, wxloginAction,
} from '@ivymobi/orbit-core/business/user';
import configureStore from '@ivymobi/orbit-core/business/configureStore';
import { acceptInvitationWithLoginedSessionAction } from '@ivymobi/orbit-core/business/tenant';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { DeviceType, InnerUserContext } from './orbit-context';
import './services/i18n';
import * as api from './api';
import themeConfig from './services/theme';
import Loader from './components/loaders';
import Notification from './layout/notification';
import ErrorBoundary from './components/errorBoundles';
import User from './users';
import Invitations from './users/invitations';
import WechatCode from './users/wechatCode';
import { ShareProductDetailView } from './shares/shareProductDetail';
import AppBar from './layout/appbar';

import reducers from './selection/reducers/selections';
import { queryParse } from '@ivymobi/orbit-core';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const AppContainer = lazy(
  () => import('./appContainer' /* webpackChunkName: "user" */)
);
const Share = lazy(
  () => import('./shares' /* webpackChunkName: "share" */)
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: '100vh',
      // minWidth: 'fit-content',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
  })
);

const Main = (props: any) => {
  const param = useParams<any>();
  const _innerUser = queryParse(window.location.search)
  const { deviceType } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [innerUser, setInnerUser] = useState(_innerUser??{});
  const doLogin = (userData) => {
    userData.logout = logout;
    setUser(userData);
  };

  const wechatCodeLogin = useCallback((loginData) => {
    dispatch(wxloginAction(loginData)).then((userData) => {
      doLogin(userData);
      window.parent.window.setUser1(userData);
      // location.reload();
      // history.replace('/');
    },
      (err) => {
        window.parent.window.onLoginError(err);
        console.error(err);
      }
    );
  }, [dispatch]);

  const login = useCallback((loginData) => {
    dispatch(loginAction(loginData)).then((userData) => {
      doLogin(userData);
      history.replace('/');
    }, (err) => {
      console.error(err);
    });
  }, [dispatch]);

  const signup = useCallback((signupData) => {
    dispatch(signupAction(signupData)).then((userData) => {
      doLogin(userData);
      history.replace('/');
    },
      (err) => {
        console.error(err);
      });
  }, [dispatch]);

  const logout = useCallback(() => {
    console.log('调用登出')
    dispatch(logoutAction()).then(() => {
      console.log('登出成功');
      setUser({});
    }, (err) => {
      console.log('登出失败', err);
      setUser({});
    });
  }, [dispatch, setUser]);

  const acceptInvitation = useCallback((id, data,) => {
    const doInvitation = user.token
      ? dispatch(acceptInvitationWithLoginedSessionAction({ user, id, data }))
      : dispatch(acceptInvitationWithRegisterAction({ id, data }))
        .then((userData) => { doLogin(userData); });

    return doInvitation
      .then(() => {
        history.replace('/choose');
      });
  }, [dispatch, user, setUser, logout]);

  useEffect(() => {
    console.log('App启动后，自动上次的登录用户');
    dispatch(resumeLastSessionAction()).then((userData) => {
      userData.logout = logout;
      setUser(userData);
    }, (err) => {
      console.log('err == ', err)
      setUser({})
    });
  }, []);

  // if (isNull(user)) {
  //   return (<Loader />);
  // }
  return (
    <div className={classes.root}>
      {/* <AppBar style={{backgroundColor:'#fff'}}>
        <TenantLogo srcUrl={'https://static-cdn.yangbentong.com/61ee3e47b94f221cfa347997.jpg'}/>
      </AppBar>

      <CacheSwitch>
        <Route exact path='/solutions'>
          <Solutions />
        </Route>
        <Route exact path='/' >
          <Landing />
        </Route>
      </CacheSwitch> */}
  
      <DeviceType.Provider value={deviceType}>
        <InnerUserContext.Provider value={innerUser}>

          
          <CacheSwitch>
            <Route path="/user">
              <User login={login} signup={signup} />
            </Route>

            <CacheRoute exact path={['/shares/:id']} >
              <ErrorBoundary>
                <Share user={user} onLogin={doLogin} />
              </ErrorBoundary>
            </CacheRoute>
            <CacheRoute exact path={['/share/ProductDetail/:shareId/:id']}>
              <ShareProductDetailView />
            </CacheRoute>

            <AppContainer doLogin={doLogin} login={login} logout={logout} user={user} />
          </CacheSwitch>
        </InnerUserContext.Provider>
      </DeviceType.Provider>
    </div>
  );
};

export default function OrbitApp() {
  const store = configureStore(api, { });
  const theme = createTheme(adaptV4Theme(themeConfig));

  const [deviceType, setDeviceType] = useState('desktop');
  const getDevice = () => {
    let screenWidth = document.body.offsetWidth
    if (screenWidth < 768) {
      setDeviceType('mobile')
    } else {
      setDeviceType('desktop')
    }
  };
  useEffect(() => {
    getDevice()
    window.addEventListener('resize', () => {
      getDevice()
    })
  }, []);

  return (
    <BrowserRouter>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Suspense fallback={<Loader />} >
              <Main deviceType={deviceType} />
              <Notification />
            </Suspense>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}
