import { get } from 'lodash';
import * as React from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Typography,
  CheckboxProps,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';

import { Validate } from './validate';

interface CheckboxGroupsInputProps<T> extends CheckboxProps {
  name: string;
  label?: string;
  validate?: Validate[];
  options: Array<T>;
  getOptionKey?: (option: T) => React.Key;
  getOptionLabel?: (option: T) => string | number;
  getOptionValue?: (option: T) => unknown;
  getDisabled?: (option: T) => boolean;
  row?: boolean;
  disabled?: boolean;
}

const defaultGetOptionKey = (option) => get(option, 'id');
const defaultGetOptionLabel = (option) => get(option, 'label');
const defaultgetOptionValue = (option) => get(option, 'value');

export default function CheckboxGroupsInput<T>(props: CheckboxGroupsInputProps<T>) {
  const {
    name, label = name,
    validate = [], row,
    options,
    disabled,
    getOptionKey = defaultGetOptionKey,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultgetOptionValue,
    getDisabled = () => disabled,
    ...rest
  } = props;
  const methods = useFormContext();
  const { errors, control, getValues } = methods;
  const me = useForm();
  const defaultValue = get(control.defaultValuesRef.current, name, []);

  const error = get(errors, name);
  const errorMessage = get(errors, [name, 'message']);

  const validations = validate.reduce((acc, v) => Object.assign(acc, v), {});
  const required = Boolean(get(validations, 'required', false));

  const getOptionSelected = (values = [], option) => {
    return values.includes(getOptionValue(option));
  };
  const styles: any = createStyles({
    formControlLabel: { fontSize: '0.9rem', 
      '& label': { fontSize: '0.9rem' } }
  });
  const handleChange = (e, isChecked, onChange) => {
    const oldValues = getValues(name);
    const value = e.target.value;
    const newValues = isChecked
      ? [value].concat(oldValues)
      : [].concat(oldValues.filter(v => v !== value));
    onChange(newValues);
  };

  return (
    <FormControl component="fieldset"
      required={required} error={Boolean(error)}
      margin="normal"
    >
      <FormLabel >{label}</FormLabel>
      <FormGroup row={row} title={label} >
        {options.map((option) => (
          <Controller key={getOptionKey(option)}
            control={control} name={name} rules={validations}
            defaultValue={defaultValue}
            render={({ onChange, onBlur, value, name }) => (
              <FormControlLabel
                label={<Typography style={styles.formControlLabel}>{getOptionLabel(option)}</Typography>}
                control={<Checkbox
                  color="primary"
                  name={name}
                  checked={getOptionSelected(value, option)}
                  value={getOptionValue(option)}
                  onChange={(e, isChecked) => {
                    handleChange(e, isChecked, onChange);
                  }}
                  onBlur={onBlur}
                  disabled={getDisabled(option)}
                  {...rest}
                />}
              />
            )}>
          </Controller>
        ))}
      </FormGroup>
      <Typography variant="caption" color="error">
        {errorMessage}
      </Typography>
    </FormControl>
  );
}


export  function UploadCheckboxGroupsInput<T>(props: CheckboxGroupsInputProps<T>) {
  const {
    name, label = name, setCustomValue,
    validate = [], row,
    options,
    disabled,
    getOptionKey = defaultGetOptionKey,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultgetOptionValue,
    getDisabled = () => disabled,
    ...rest
  } = props;
  const methods = useFormContext();
  const { errors, control, getValues } = methods;
  const me = useForm();
  const defaultValue = get(control.defaultValuesRef.current, name, []);

  const error = get(errors, name);
  const errorMessage = get(errors, [name, 'message']);

  const validations = validate.reduce((acc, v) => Object.assign(acc, v), {});
  const required = Boolean(get(validations, 'required', false));

  const getOptionSelected = (values = [], option) => {
    return values.includes(getOptionValue(option));
  };
  const styles: any = createStyles({
    formControlLabel: { fontSize: '0.9rem', 
      '& label': { fontSize: '0.9rem' } }
  });
  const handleChange = (e, isChecked, onChange) => {
    const oldValues = getValues(name);
    const value = e.target.value;
    const newValues = isChecked
      ? [value].concat(oldValues)
      : [].concat(oldValues.filter(v => v !== value));
    setCustomValue(name, newValues);
    onChange(newValues);
  };

  return (
    <FormControl component="fieldset"
      required={required} error={Boolean(error)}
      margin="normal"
    >
      <FormLabel >{label}</FormLabel>
      <FormGroup row={row} title={label} >
        {options.map((option) => (
          <Controller key={getOptionKey(option)}
            control={control} name={name} rules={validations}
            defaultValue={defaultValue}
            render={({ onChange, onBlur, value, name }) => (
              <FormControlLabel
                label={<Typography style={styles.formControlLabel}>{getOptionLabel(option)}</Typography>}
                control={<Checkbox
                  color="primary"
                  name={name}
                  checked={getOptionSelected(value, option)}
                  value={getOptionValue(option)}
                  onChange={(e, isChecked) => {
                    handleChange(e, isChecked, onChange);
                  }}
                  onBlur={onBlur}
                  disabled={getDisabled(option)}
                  {...rest}
                />}
              />
            )}>
          </Controller>
        ))}
      </FormGroup>
      <Typography variant="caption" color="error">
        {errorMessage}
      </Typography>
    </FormControl>
  );
}