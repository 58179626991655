import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  IconButton, 
  Input, 
  InputLabel, 
  InputAdornment, 
  FormControl
} from '@mui/material';

import { Search, Clear } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
  }),
);

export default function SearchInput ({doSearch, doCancel=() => {console.log('111');}}) {
  const classes = useStyles();

  const [value, setValue] = React.useState('');
  const handleChange = (event) => setValue(event.target.value);
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl className={clsx(classes.margin, classes.textField)}>
      <InputLabel htmlFor="searchInput">Search</InputLabel>
      <Input
        id="searchInput"
        type='text'
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            {value&&<IconButton
              onClick={() => {setValue(''); doCancel();}}
              onMouseDown={handleMouseDown}
              size="large"><Clear/></IconButton>}
            <IconButton
              onClick={() => doSearch(value)}
              onMouseDown={handleMouseDown}
              size="large">
              <Search />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}